import { Text, StyleSheet, View } from "react-native";
import { colors, fonts } from "../styles/Styles";
import useUpdateValue from "../hooks/useUpdateValue";
import AppGaugeChart from "../components/AppGaugeChart";

const BoochBrewScreen = ({ thingId }) => {
    //const [isFahrenheit, setIsFahrenheit] = useState(false);
    const [isUpdatingValue, setUpdateValue, thingValue] = useUpdateValue(thingId);
    
    if (!thingValue) return null;

    // useEffect(() => {
    //     // Fetch the user's preference from AsyncStorage when the app starts
    //     const fetchTemperaturePreference = async () => {
    //         try {
    //             const value = await AsyncStorage.getItem('temperaturePreference');
    //             if (value !== null) {
    //             setIsFahrenheit(value === 'fahrenheit');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching temperature preference', error);
    //         }
    //     };
    //     fetchTemperaturePreference();
    // }, []);
    
    // useEffect(() => {
    //     // Save the user's preference to AsyncStorage whenever it changes
    //     const saveTemperaturePreference = async () => {
    //         try {
    //         const preference = isFahrenheit ? 'fahrenheit' : 'celsius';
    //         await AsyncStorage.setItem('temperaturePreference', preference);
    //         } catch (error) {
    //         console.error('Error saving temperature preference', error);
    //         }
    //     };
    //     saveTemperaturePreference();
    // }, [isFahrenheit]);

    return (
        <View style={styles.container}>
            <View style={styles.chartsContainer}>
                <AppGaugeChart 
                    length = {230} 
                    value = {10}
                    unit = '°'
                    colours = {[colors.colorDarker, '#3F504F', '#4E7270', '#5E9390', colors.colorTurqoise]}
                />
                <AppGaugeChart 
                    length = {230} 
                    value = {10}
                    unit = ' ppm'
                    colours = {[colors.colorDarker, '#3F504F', '#4E7270', '#5E9390', colors.colorTurqoise, colors.colorDarker, '#3F504F', '#4E7270', '#5E9390', colors.colorTurqoise, colors.colorDarker, '#3F504F', '#4E7270', '#5E9390', colors.colorTurqoise, colors.colorDarker, '#3F504F', '#4E7270', '#5E9390', colors.colorTurqoise]}
                />
            </View>
            <Text style={styles.text}>{JSON.stringify(thingValue)}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    chartsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    text: {
        fontFamily: fonts.fontPrimary,
        color: colors.colorWhite,
        fontSize: 12,
        fontWeight: "600",
        textTransform: "uppercase",
    },
});

export default BoochBrewScreen;
