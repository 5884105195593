export const emailGetById = `
    query EmailsGetById($emailId: ID!) {
        thingGetById(emailId: $emailId) {
            email
            isSubscribed
            isCustomer
            coupon
        }
    }
`;

export const emailSubscribe = `
    mutation EmailSubscribe($emailId: ID!) {
        emailSubscribe(emailId: $emailId) {
            email
            isSubscribed
            isCustomer
            coupon
        }
    }
`;

export const emailUnsubscribe = `
    mutation EmailUnsubscribe($emailId: ID!) {
        emailUnsubscribe(emailId: $emailId) {
            email
            isSubscribed
            isCustomer
            coupon
        }
    }
`;