import { API, graphqlOperation } from "aws-amplify";
import {
    emailGetById,
    emailSubscribe,
    emailUnsubscribe,
} from "./GraphqlOperations";

export const apiEmailGetById = async (emailId) => {
    try {
        const response = await API.graphql(
            graphqlOperation(emailGetById, {
                emailId: emailId,
            })
        );
        console.log("apiEmailGetById:", response);
        if (response?.data?.emailGetById) {
            return response.data.emailGetById;
        } else {
            console.error("Error fetching Email");
            return;
        }
    } catch (error) {
        console.error("Error fetching Email:", error);
        return;
    }
};

export const apiEmailSubscribe = async (emailId) => {
    try {
        const response = await API.graphql(
            graphqlOperation(emailSubscribe, {
                emailId: emailId,
            })
        );
        console.log("apiEmailSubscribe:", response);
        return response.data.emailSubscribe;
    } catch (error) {
        console.error("Error subscribing email:", error);
        return null;
    }
};

export const apiEmailUnsubscribe = async (emailId) => {
    try {
        const response = await API.graphql(
            graphqlOperation(emailUnsubscribe, {
                emailId: emailId,
            })
        );
        console.log("apiEmailUnsubscribe:", response);
        return response.data.emailUnsubscribe;
    } catch (error) {
        console.error("Error unsubscribing email:", error);
        return null;
    }
};
