import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Path, Circle, Text } from 'react-native-svg';
import { iconSize, colors, width, fonts } from "../styles/Styles";


const AppGaugeChart = ({ 
    length = 360, 
    value = 270,
    unit = '',
    colours,
}) => {
    const gapSize = 15;
    const start = 180 + (gapSize / 2);

    const circleRadius = 50;
    const segmentCount = colours.length;
    const strokeWidth = 10;
    const startAngle = start + ((360 - length) / 2);
    const totalGapSize = gapSize * (segmentCount );
    const segmentLength = (length - totalGapSize) / segmentCount;
    const blobRadius = 8;
    const canvasSize = circleRadius * 2 + strokeWidth * 2; // Ensure canvas is large enough to fit the circle and stroke
    const centerX = canvasSize / 2;
    const centerY = canvasSize / 2;

    const drawSegment = (startAngle, endAngle, color) => {
        const startX = centerX + circleRadius * Math.sin(Math.PI * startAngle / 180);
        const startY = centerY - circleRadius * Math.cos(Math.PI * startAngle / 180);
        const endX = centerX + circleRadius * Math.sin(Math.PI * endAngle / 180);
        const endY = centerY - circleRadius * Math.cos(Math.PI * endAngle / 180);
        const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

        return (
            <Path
                d={`M ${startX},${startY} A ${circleRadius},${circleRadius} 0 ${largeArcFlag},1 ${endX},${endY}`}
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                fill="none"
            />
        );
    };

    const getBlobColor = (value) => {
        const segmentIndex = Math.floor((value / 100) * segmentCount);
        const mapValueToSegment = Math.min(segmentIndex, segmentCount - 1);
        return colours[mapValueToSegment];
    };

    const getBlobLocationX = () => {
        return (centerX + circleRadius * Math.sin(Math.PI * (startAngle + (value * length / 100)) / 180));
    }
    const getBlobLocationY = () => {
        return (centerY - circleRadius * Math.cos(Math.PI * (startAngle + (value * length / 100)) / 180));
    }

    return (
            <View style={styles.container}>
                <Svg width={canvasSize} height={canvasSize}>
                {Array.from({ length: segmentCount }).map((_, index) => {
                    const segmentStart = startAngle + index * (segmentLength + gapSize);
                    const segmentEnd = segmentStart + segmentLength;
                    const color = colours[index % colours.length]; // Repeat colors if there are more segments than colors
                    return (
                        <React.Fragment key={index}>
                            {drawSegment(segmentStart, segmentEnd, color)}
                        </React.Fragment>
                    );
                })}

                <Circle
                    cx={getBlobLocationX()}
                    cy={getBlobLocationY()}
                    r={blobRadius}
                    fill={getBlobColor(value)}
                />
                <Circle
                    cx={getBlobLocationX()}
                    cy={getBlobLocationY()}
                    r={blobRadius - 4}
                    fill={colors.colorDarkest}
                />
                <Text
                    x={centerX}
                    y={centerY - (circleRadius / 5)}
                    fill="#ffffff"
                    fontSize={20}
                    fontWeight="bold"
                    textAnchor="middle"
                    dy=".3em"
                    >
                    {`${value}${unit}`}
                </Text>
            </Svg>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: 'transparent',
    },
});

export default AppGaugeChart;