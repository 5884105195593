import AwsConfig from "./src/aws-exports";
import { CdkStack } from "./src/cdk-exports.json";
import { StatusBar } from "expo-status-bar";
import { Amplify } from "aws-amplify";
import { NavigationContainer } from "@react-navigation/native";
import LoggedOutNavigator from "./navigators/LoggedOutNavigator";
import LoggedInNavigator from "./navigators/LoggedInNavigator";
import { AppLoading } from "./components/AppLoading";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#general-configuration
// https://docs.amplify.aws/lib/graphqlapi/existing-resources/q/platform/js/
const CdkConfig = {
    // app
    aws_cognito_region: CdkStack.awscognitoregion,
    aws_user_pools_id: CdkStack.awsuserpoolsid,
    aws_user_pools_web_client_id: CdkStack.awsuserpoolswebclientid,
    aws_cognito_identity_pool_id: CdkStack.awscognitoidentitypoolid,
    aws_mandatory_sign_in: CdkStack.awsmandatorysignin,
    //aws_appsync_graphqlEndpoint: CdkStack.awsappsyncgraphqlendpoint,
    //aws_appsync_region: CdkStack.awsappsyncregion,
    //aws_appsync_authenticationType: CdkStack.awsappsyncauthenticationtype,
    aws_user_files_s3_bucket_region: CdkStack.awsuserfiless3bucketregion,
    aws_user_files_s3_bucket: CdkStack.awsuserfiless3bucket,
    // website
    aws_appsync_graphqlEndpoint: CdkStack.awsappsyncemailsapiendpoint,
    aws_appsync_region: CdkStack.awsappsyncregion,
    aws_appsync_authenticationType: CdkStack.awsappsyncemailsapiauth,
    aws_appsync_apiKey: CdkStack.awsappsyncemailsapikey
};

const configuration = {
    ...AwsConfig,
    ...CdkConfig,
};

Amplify.configure(configuration);

const App = () => {
    return (
        <NavigationContainer>
            <StatusBar style="light" />
            <AuthProvider>
                <NavigationSelector />
            </AuthProvider>
        </NavigationContainer>
    );
};

const NavigationSelector = () => {
    const auth = useAuth();

    if (auth.isUserLoggedIn === null) return <AppLoading />;
    return auth.isUserLoggedIn ? <LoggedInNavigator /> : <LoggedOutNavigator />;
};

export default App;


// import React, { useState } from 'react';
// import './App.css';
// import { Amplify } from 'aws-amplify';
// import AwsConfig from "./aws-exports";
// import CdkExports from "./cdk-exports.json"; // TODO fix dynamic import as this file currently is copy paste from mobile
// import { apiEmailSubscribe } from "./graphql/GraphqlApi";

// const CdkStack = CdkExports.CdkStack;

// const CdkConfig = {
//   aws_appsync_graphqlEndpoint: CdkStack.awsappsyncemailsapiendpoint,
//   aws_appsync_region: CdkStack.awsappsyncregion,
//   aws_appsync_authenticationType: CdkStack.awsappsyncemailsapiauth,
//   aws_appsync_apiKey: CdkStack.awsappsyncemailsapikey
// };

// const configuration = {
//   ...AwsConfig,
//   ...CdkConfig,
// };

// Amplify.configure(configuration);

// function App() {
//   console.log("HEJHEJ");
//   const [email, setEmail] = useState('');

//   const handleSubscribe = async (event) => {
//     event.preventDefault();
//     if (email) {
//       const response = await apiEmailSubscribe(email);
//       console.log(response);
//       // Add any additional logic you want after successful subscription
//     }
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <p>Hello World3</p>
//         <form onSubmit={handleSubscribe}>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Enter your email"
//             required
//           />
//           <button type="submit">Subscribe</button>
//         </form>
//       </header>
//     </div>
//   );
// }

// export default App;
