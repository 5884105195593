import { createStackNavigator } from "@react-navigation/stack";
// import SignInScreen from "../screens/SignInScreen";
// import SignUpScreen from "../screens/SignUpScreen";
// import VerifySignUpScreen from "../screens/VerifySignUpScreen";
// import ResetPasswordScreen from "../screens/ResetPasswordScreen";
// import { colors } from "../styles/Styles";
//import { useState } from "react";
import React, { useState, useRef } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { AppTextInput } from "../components/AppTextInput";
import { iconSize, colors } from "../styles/Styles";
import Ionicons from "react-native-vector-icons/Ionicons";
import { apiEmailSubscribe } from "../graphql/api-emails/GraphqlApi";



const LoggedOutStackNavigator = createStackNavigator();

const LoggedOutNavigator = () => {
    const [email, setEmail] = useState('');
    const emailRef = useRef("");

    const handleSubscribe = async () => {
        if (email) {
        const response = await apiEmailSubscribe(email);
        console.log(response);
        }
    };
    // return (
    //     <LoggedOutStackNavigator.Navigator screenOptions={styles.tabBar}>
    //         <LoggedOutStackNavigator.Screen name="SignInScreen" component={SignInScreen} />
    //         <LoggedOutStackNavigator.Screen name="SignUpScreen" component={SignUpScreen} />
    //         <LoggedOutStackNavigator.Screen
    //             name="VerifySignUpScreen"
    //             component={VerifySignUpScreen}
    //         />
    //         <LoggedOutStackNavigator.Screen
    //             name="ResetPasswordScreen"
    //             component={ResetPasswordScreen}
    //         />
    //     </LoggedOutStackNavigator.Navigator>
    // );
    return (
        <View style={styles.container}>
            <Text>Subscribe to updates</Text>
            <AppTextInput
                inputRef={emailRef}
                value={email}
                setValue={setEmail}
                placeholder={"Your email address"}
                isSecure={false}
                icon={"mail"}
                keyboardType={"default"}
            />
            <TouchableOpacity onPress={() => handleSubscribe()} >
                <Ionicons
                    name={"checkmark-circle"}
                    size={iconSize.medium}
                    style={{ color: colors.colorFocus }}
                />
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      height: 40,
      margin: 12,
      borderWidth: 1,
      padding: 10,
    },
});

export default LoggedOutNavigator;
