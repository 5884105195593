import { useEffect, useState } from "react";
import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
} from "react-native";
import { Auth } from "aws-amplify";
import { useNavigation } from "@react-navigation/native";
import Ionicons from "react-native-vector-icons/Ionicons";
import { iconSize, colors, fonts } from "../styles/Styles";
import { apiThingsList, apiThingValueChange } from "../graphql/api-emails/GraphqlApi";
import { AppLoading } from "../components/AppLoading";
import useThingsSubscription from "../hooks/useThingsSubscription";
import { getTypeIcon } from "../types/Type";
import { useUserThings } from "../contexts/UserThingsContext";

const HomeScreen = () => {
    //const navigation = useNavigation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    //const [refreshing, setRefreshing] = useState(false);
    //const { userThings, setUserThings } = useUserThings();
    useThingsSubscription(user, setUserThings);

    useEffect(() => {
        console.log("useEffect getUser");
        getUser();
    }, []);

    useEffect(() => {
        console.log("useEffect getUserThings");
        getUserThings();
    }, []);

    const getUserThings = async () => {
        try {
            const things = await apiThingsList();

            // Map over things and return a new array without mutating the original one.
            const updatedThings = things.map((thing) => {
                const thingValue = JSON.parse(thing.value);
                thingValue.mobile_value.readwrite.network = "offline";
                thing.value = JSON.stringify(thingValue);
                return thing;
            });

            setUserThings(updatedThings);
            await Promise.all(
                updatedThings.map((thing) =>
                    apiThingValueChange(thing.id, thing.value)
                )
            );
        } catch (error) {
            console.error("Error getting user things:", error);
        } finally {
            setLoading(false);
        }
    };

    const getUser = async () => {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
        console.log("Username: ", currentUser.username);
        console.log("Email: ", currentUser.attributes.email);
    };

    return (
        <>
            <View style={styles.containerScreen}>
                <View style={styles.containerHeader}>
                    <Text style={styles.textHeader}>Home</Text>
                </View>
            </View>
            {loading && <AppLoading />}
        </>
    );
};

const styles = StyleSheet.create({
    containerScreen: {
        flex: 1,
        backgroundColor: colors.colorDarkest,
    },
    containerHeader: {
        marginVertical: 50,
        marginHorizontal: 10,
        flexDirection: "row",
        backgroundColor: colors.colorDarkest,
        justifyContent: "space-between",
        alignItems: "center",
    },
    containerDeviceList: {
        backgroundColor: colors.colorDarker,
        flexDirection: "row",
        marginHorizontal: 10,
        marginVertical: 5,
        borderRadius: 20,
        paddingVertical: 20,
        paddingHorizontal: 15,
        alignItems: "center",
        justifyContent: "space-between",

        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.3,
        shadowRadius: 5,
        elevation: 5,
    },
    containerDevice: {
        flexDirection: "row",
        alignItems: "center",
    },
    containerDeviceTopLayerOffline: {
        color: colors.colorDarkest,
        opacity: 0.4,
    },
    containerDeviceTopLayerOnline: {},
    textHeader: {
        fontFamily: fonts.fontPrimary,
        fontSize: 30,
        fontWeight: "bold",
        color: colors.colorWhite,
        marginRight: 10,
        marginLeft: 10,
    },
    textMedium: {
        fontFamily: fonts.fontPrimary,
        fontSize: 16,
        color: colors.colorWhite,
        marginRight: 10,
        marginLeft: 10,
    },
    textSmall: {
        fontFamily: fonts.fontPrimary,
        fontSize: 10,
        color: colors.colorBrighter,
        marginRight: 10,
        marginLeft: 10,
    },
});

export default HomeScreen;
